.web-header-container {
    position: relative;
    left: min(15vw, 8vh);
    top: min(4vh, 10vw);
    display: flex;
    width: 100%;
    height: fit-content;
    align-items: center;
    align-content: center;
}

.logo {
    background-image: url(../../../img/deadpool.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 4vw;
    height: 4vw;
}

#photoGallery-text {
    font-family: 'Inter', system-ui;
    font-style: normal;
    font-weight: 200;
    font-size: 2vw;
    line-height: 2.2vw;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 1vw;

    /* identical to box height */
    letter-spacing: 0.2em;

    color: #3C3434;
}

.web-body-container {
    width: 100%;
    position: relative;
    height: min(70vh, 45em);
    display: inline-flex;
    justify-content: center;
    margin-top: min(5vh, 5em);
    align-items: center;
}

.picture-card-component {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    padding: 1vw;
    flex-direction: column;
}

#left {
    mask-image: linear-gradient(to right, transparent 30%, black 100%);
    height: 70%;
    justify-content: center;
    justify-items: center;
}

#right {
    mask-image: linear-gradient(to left, transparent 30%, black 100%);
    height: 70%;
    justify-content: center;
    justify-items: center;
}

#center {
    width: 170%;
    justify-content: center;
    justify-items: center;
}

.picture-text {
    font-family: 'Inter', system-ui;
    font-style: normal;
    font-weight: 200;
    font-size: max(1.5vw, 2vh);
    line-height: max(1.7vw, 2.2vh);
    display: block;
    margin-bottom: 1.5%;

    /* identical to box height */
    text-align: center;
    letter-spacing: 0.185em;

    color: #3F3743;
}

.picture-card {
    clip-path: inset(0% 0% 0% 0% round 48px);
    position: relative;
    height: 100%;
    width: 100%;

    margin-right: 1vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
}

.arrow {
    background-image: url(../../../img/arrow.svg);
    fill: #141313;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;


    width: 2vw;
    height: 2vw;
    position: absolute;
    top: 50%;
    z-index: 2;
}

.arrow:hover {
    scale: 120%;
}

#gallery1 {
    background-image: url(../../../img/calendar.png);
}

#gallery2 {
    background-image: url(../../../img/loveAlbum.png);
}

#gallery3 {
    background-image: url(../../../img/osaka.png);
}

#gallery4 {
    background-image: url(../../../img/tokyo.png);
}

#gallery5 {
    background-image: url(../../../img/amsterdam.png);
}
