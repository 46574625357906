.Menu-bar-rect {
  position: absolute;
  width: 15vw;
  height: min(7vh, 6vw);
  left: max(75vw, 80%);
  top: max(85vh, 87%);

  background: rgba(41, 50, 52, 0.85);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2.5vw;
}

#opened-menu {
  width: 30vw;
  height: min(34vh, 26vw);
  left: calc(max(90vw, 80% + 15vw) - 30vw);
  top: calc(max(85vh, 87%) + min(7vh, 6vw) - min(34vh, 26vw));
}

#opened-profile {
  width: min(40vh, 30vw);
  height: min(32vh, 28vw);
  left: calc(max(75vw, 80% + 15vw) - min(40vh, 30vw));
  top: calc(max(85vh, 87%) + min(7vh, 6vw) - min(32vh, 28vw));
}

.icon-bar {
  position: absolute;
  bottom: min(1.5vh, 0.5vw);
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-container {
  position: relative;
  top: 0.5vh;
  left: 0.5vw;
  bottom: 10vh;
  display: grid;
  justify-content: left;
  align-items: flex-start;
  height: fit-content;

}

.menubar-icon {
  background-size: 4vh;
  background-color: #F9F5F4;

  width: 5vw;
  height: 5vh;
  margin: min(0.2vh, 0.5vw);
}

.menubar-icon:hover {
  background-color: #ffffff;
  transform: scale(1.15);
  filter: drop-shadow(4px 5px 5px rgba(0, 0, 0, 0.45));
}

#home-icon {
  -webkit-mask: url('../../img/home icon.png') no-repeat center;
  mask: url('../../img/home icon.png') no-repeat center;
  -webkit-mask-size: min(4vh, 3vw);
  mask-size: min(4vh, 3vw);
  padding-left: 1vw;
}

#profile-icon{
  -webkit-mask: url('../../img/profile.png') no-repeat center;
  mask: url('../../img/profile.png') no-repeat center;
  -webkit-mask-size: min(4vh, 3vw);
  mask-size: min(4vh, 3vw);
}

#menu-icon {
  -webkit-mask: url('../../img/hamburger menu.png') no-repeat center;
  mask: url('../../img/hamburger menu.png') no-repeat center;
  -webkit-mask-size: min(4vh, 3vw);
  mask-size: min(4vh, 3vw);
  padding-right: 1vw;
}

.text-button {
  background: transparent;
  font-family: 'Saira Condensed', system-ui;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-left: 1vw;
  margin-right: 2vw;

  /* or 48px */
  letter-spacing: 0.37em;

  color: #BAB6B5;
  position: inherit;
  width: 100%;
  text-align: left;
}

.text-button:hover {
  color: #ffffff;
}


#profile {
  font-size: calc(min(32vh, 28vw)/8 - min(1.2vh, 1.2vw));
  padding: min(1.2vh, 0.8vw);
}

#profile:hover {
  font-size: calc(min(32vh, 28vw)/8 - min(1vh, 1vw));
  padding: min(1.2vh, 0.8vw);
}

#menu {
  font-size: max(1.2vh, 2.2vw);
  padding: min(1.2vh, 0.8vw);
}

#menu:hover {
  font-size: max(1.4vh, 2.4vw);
}

