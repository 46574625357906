div {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
}

button {
  border-radius: 0;
  border-color: transparent;
  background-color: transparent;
}

image {
  border: 0;
  margin: 0;
  padding: 0;
}

.window-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}